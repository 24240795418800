@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root, body, #root {
  height: 100%;
  --primary-color: #0071b3;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.nirbby-select-border-color-primary::after,
.nirbby-select-border-color-primary::before {
  border-color: var(--primary-color)!important;
}

.nirbby-tab-border-bottom-primary {
  border-bottom-color: var(--primary-color)!important;
}